import React from 'react';
import Nav from './Components/Nav/Nav'
import Main from './Components/Main/Main'

import './App.css'


function App() {
  
  

  return (
    <div className="App">
      <Nav />
      <Main />
    </div>
  );
}

export default App;
