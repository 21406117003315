import React from 'react'
// import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import Form from '../Form/Form'

import './Main.scss'


function Main(){

    
    return(
        <div className='spacing-container' id='home'>
            <div className='content-container'>
                <div className='home-container'>
                <img 
                    src={require("../../handshake.png")}
                    // style={{width: 900, height: 400}}
                    id='hands'
                    alt="logo"
                    />
                </div>
                <div className='about-container'>
                    <div className='about-box'>
                        <div className='about-img'>
                            <img 
                                id='chadncourt'
                                src={require('../../ChadnCourt.PNG')}
                                // style={{width: 250, height: 250}}
                                alt='chadncourt'
                            />
                        </div>
                        <div className='about-content'>
                            <h1 id='about-title'>About Us</h1>
                            <span id='about-paragraph'>Elevated Care is an organization serving people with disabilities. It is our mission to provide elevated life experiences to people with disabilities.
                            </span>
                        <Button
                        //https://myasdf.org/media-center/articles/how-your-autistic-child-can-benefit-from-equine-therapy/ as well as pictures of taylor
                        //Clients are individuals, and you’re caring for the PERSON, not their condition.
                        onClick={() => window.scrollTo({ top: 1400, behavior: 'smooth' })}
                        > Get Started </Button>
                        </div>
                    </div>
                </div>
                <div className='mission-container'>
                    <h1>Mission</h1>
                    <div>
                        Our Day Program mission is to provide elevated life experiences to people with disabilities. 
                    </div>
                
                </div>
                <div className='day-program-container'>
                    <h1>Day Program</h1>
                    <div>
                    The Elevated Care Day Program offers innovative solutions for individuals who may struggle under more traditional agency practices. Our goal is to truly elevate the life experience for individuals in our program that otherwise may not have access to such opportunities like their neurotypical peers. Our Day Service offers unique access to a community based Equestrian facility. This allows us to incorporate the therapeutic experience of horses - which have been proven to be highly beneficial for persons with mental and physical disabilities. In conjunction with equine therapeutic experiences, we do many community based activities which help adults develop social skills for work, finding hobbies, and creative outlets through which they can better express themselves. 
                    </div>
                </div>
                <Form />

                <div className='website-footer'>
                    <div>
                        <img 
                            src={require('../../EC-Logo.PNG')}
                            alt='Logo'
                        />
                    </div>
                    <div>
                        <Button><TwitterIcon /></Button>
                        <Button><FacebookIcon /></Button>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Main