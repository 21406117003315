import React from 'react'
import Button from '@material-ui/core/Button';
import PhoneIcon from '@material-ui/icons/Phone';
import './Nav.scss'


export default function Nav(props){

  return(
    <div className='nav-container'>
      <div className='nav-list'>
        <div id='nav-logo' className='nav-list-item' >
          <img
            id='logo'
            src={require("../../EC-Banner.PNG")}
            alt='Elevated Care'
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          />
        </div>
        <div className='nav-list-item'
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        >
          <span>Home</span>
        </div>
        <div className='nav-list-item'
        onClick={() => window.scrollTo({ top: 450, behavior: 'smooth' })}
        >
          <span>About Us</span>
        </div>
        <div className='nav-list-item'
        onClick={() => window.scrollTo({ top: 1050, behavior: 'smooth' })}
        >
          <span>Day Program</span>
        </div>
        <div className='nav-list-item'
        onClick={() => window.scrollTo({ top: 1400, behavior: 'smooth' })}
        >
          <span>Contact Us</span>
        </div>
        <div>
          <Button 
          href='tel:+801-913-9665'
          style={{marginLeft: 20, marginRight:-45}}
          variant='contained'
          color='primary'>
            <PhoneIcon 
              style={{marginRight: 5}}
            /> 801-879-2888
          </Button>
        </div>
      </div>
    </div>
  )
}

