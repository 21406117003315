import React, {useState} from 'react'
import axios from 'axios'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
// import {ToastContainer, toast, Zoom, Bounce} from 'react-toastify'


import './Form.scss'

function Form(){

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')

    const handleSubmit = () => {
        // e.preventDefault();
        console.log('hello')
        // toast.success('Email Sent')

        const dataToSubmit = {
            firstName,
            lastName,
            email,
            phone,
            message
        }

        console.log(dataToSubmit)
        
        axios.post('api/send', dataToSubmit)
            .then(
                // <>
                //     <ToastContainer 
                //         draggable={false}
                //         transition={Zoom}
                //         autoClose={8000}
                //     />
                // </>,
                // setFirstName(''),
                // setLastName(''),
                // setEmail(''),
                // setPhone(''),
                // setMessage('')
                //message similiar to toastify that email was sent
            )
            .catch(err => {
                console.log(err)
            })
    }

return(
<div className='contact-container'>
    <div className='contact-box'>
        <h1>Contact Us</h1>
        <form>
            <div className='textfield-container'>
                <TextField 
                    //  id="outlined-helperText"
                    className='textfield'
                    label="First Name"
                    defaultValue="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    //  helperText="Some important text"
                    variant="outlined"
                    />
                <TextField 
                    //  id="outlined-helperText"
                    className='textfield'
                    style={{marginLeft: 10}}
                    label="Last Name"
                    defaultValue="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    //  helperText="Some important text"
                    variant="outlined"
                />
            </div>
            <div className='textfield-container'>
                <TextField 
                    //  id="outlined-helperText"
                    className='textfield'
                    label="Email"
                    defaultValue="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    //  helperText="Some important text"
                    variant="outlined"
                />
                <TextField 
                    //  id="outlined-helperText"
                    style={{marginLeft: 10}}
                    className='textfield'
                    label="Phone Number"
                    defaultValue="Phone Number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    //  helperText="Some important text"
                    variant="outlined"
                />
            </div>
            <div className='textfield-container'>
                <TextField 
                    id="message"
                    className='textfield'
                    label="Message"
                    defaultValue="Message"
                    multiline
                    rowsMax={5}
                    inputProps={{maxLength: 300}}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    helperText="Max 300 Characters"
                    variant="outlined"
                />
            </div>
            <div>
                <Button 
                style={{marginLeft: 180}}
                variant='contained'
                color='secondary'
                onClick={() => handleSubmit()}
                >
                Submit
                </Button>
            </div>
        </form>
    </div>
</div>
)}

export default Form